import React from 'react';
import '../css/typography.css';

const MainDescription = (props) => {
  return (
    <section className="relative text-white bg-gradient-to-b from-blue-accent-400 to-blue-accent-200  body-font">
      <div className="absolute inset-x-0 bottom-0">
        <svg
          viewBox="0 0 224 12"
          fill="currentColor"
          className="w-full -mb-1 text-white"
          preserveAspectRatio="none"
        >
          <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
        </svg>
      </div>
      <div className="relative container mx-auto flex px-4 pt-16 pb-36 items-center justify-center flex-col">
        <div className="text-center lg:w-4/5 w-full">
          <h1
            className="leading-none font-roboto font-semibold tracking-tighter text-4xl sm:text-6xl mb-4 text-white"
          >
            {props.title}
          </h1>
          <p className="sm:mx-56 mx-2 text-center"></p>
          <blockquote>
            <p className="font-roboto font-semibold tracking-tight text-white my-8 text-2xl">
              {props.par1}<br />
            </p>
            <p className="mb-8 text-xl">
              {props.par2}
            </p>
          </blockquote>
        </div>
      </div>
    </section>
  )
};

export default MainDescription;
