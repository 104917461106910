import React from "react";
import { Link } from "gatsby";
import '../css/clip.css';

export const GalleryDetailed = (props) => {
  return (
    <section className="rev-parallelogram text-white body-font bg-gradient-to-b from-blue-accent-400 to-blue-accent-700">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-32">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-6 font-sans sm:text-6xl text-4xl font-roboto font-semibold leading-none tracking-tight text-white md:mx-auto">
            {props.title}
          </h2>
          {/* <p className="text-base text-gray-700 md:text-lg">
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque rem aperiam, eaque ipsa quae.
        </p> */}
        </div>
        <div className="grid gap-12 mb-8 lg:grid-cols-3 md:grid-cols-2">
          {props.vehicles.map(v => (
            <Link
              href={v.link}
              aria-label="View Item"
              className="inline-block overflow-hidden duration-300 transform bg-white rounded shadow-sm hover:-translate-y-2"
            >
              <div className="flex flex-col h-full">
                <img
                  src={v.imgSrc}
                  className="object-cover w-full h-full"
                  alt=""
                />
                <div className="flex-grow rounded-b duration-300 transform">
                  <div className="p-[1.1rem]">
                    <h6 className="mb-4 font-roboto font-bold tracking-tightest text-3xl text-center text-gray-900 leading-8">
                      {v.title}
                    </h6>
                    <p className="border-b border-gray-900 mx-24 mb-4"></p>
                    <p className="font-roboto font-semibold tracking-normal text-lg text-center text-gray-700">
                      {v.description}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          ))}

          <Link
            href="/corsi-patente/patenteB"
            aria-label="View Item"
            className="lg:col-start-2 md:col-start-1 inline-block overflow-hidden duration-300 transform bg-white rounded shadow-sm hover:-translate-y-2"
          >
            <div className="flex flex-col h-full">
              <img
                src="/vehicles/polo.jpg"
                className="object-cover w-full h-full"
                alt=""
              />
              <div className="flex-grow rounded-b duration-300 transform">
                <div className="p-[1.1rem]">
                  <h6 className="mb-4 font-roboto font-bold tracking-tightest text-3xl text-center text-gray-900 leading-8">
                    Volkswagen Nuova Polo 1.6
                  </h6>
                  <p className="border-b border-gray-900 mx-24 mb-4"></p>
                  <p className="font-roboto font-semibold tracking-normal text-lg text-center text-gray-700">
                    Mezzo utilizzato per patente B
                  </p>
                </div>
              </div>
            </div>
          </Link>

        </div>

      </div>
    </section>
  );
}

export default GalleryDetailed;