import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';



const Gallery = (props) => {
  return (
    <section className="text-gray-900 body-font">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-6 sm:mx-auto sm:text-center md:mb-10 lg:max-w-2xl">
          <h2 className="mb-1 sm:text-6xl text-4xl font-roboto font-semibold tracking-tight md:mb-2">
            {props.title}
          </h2>
        </div>

        <div className="grid gap-6 row-gap-5 lg:grid-cols-2">
          {props.gallery.map(g => (
            <div>
              <img
                className="object-cover w-full h-64 mb-6 rounded shadow-lg lg:h-80 xl:h-96"
                src={g.imgSrc}
                alt=""
              />
              <h5 className="text-gray-900 mb-2 text-xl font-roboto font-semibold tracking-normal leading-none sm:text-2xl">
                {g.title}
              </h5>
              <p className="text-gray-700 font-roboto font-semibold tracking-normal">
                {g.description}
              </p>
            </div>
          ))
          }
        </div>
      </div>
    </section >
  );
}



Gallery.defaultProps = {
  theme: 'indigo',
};

Gallery.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default Gallery;
