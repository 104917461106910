import React from "react";
import Macbook from "../../../static/sede/sede1.jpg";

const ContentAbout = () => {
  return (
    <div className="relative">
      <img
        src={Macbook}
        className="absolute inset-0 object-cover object-right w-full h-full"
        alt="Autoscuola Faro a Pisa"
      />
      <div className="relative bg-gray-800 bg-opacity-75">
        <div className="md:px-24 lg:px-12 lg:py-48 pb-12 px-4 py-8 mx-auto overflow-hidden sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
          <div className="flex flex-col items-center justify-between xl:flex-row w-full">
            <div className="sm:text-start text-center w-full mb-12 xl:mb-0 xl:pr-16 xl:w-3/4">
              <h1
                className="w-full mb-6 font-sans font-extrabold text-white 
              sm:text-8xl text-5xl"
              >
                Chi siamo
              </h1>
              <p className="leading-[4rem] font-sans font-normal sm:mt-12 
              mt-4 text-lg text-white md:text-2xl">
                Da quasi mezzo secolo, l’Autoscuola Faro opera con impegno e
                professionalità nel settore automobilistico, motociclistico e
                nautico. Sorta nel quartiere di San Martino è diventata ben
                presto un punto di riferimento per tutta la città di Pisa. <br />
                Dal 1 aprile 2014, nel solco dell'attività cinquantennale delle
                precedenti gestioni, si è insediata la nuova conduzione diretta
                da Salvatore e Fabio i quali hanno saputo iniettare nell'azienda
                quel dinamismo e quell'entusiasmo tipici di un nuovo inizio, pur
                mantenendo intatti tutti i valori di cui l'Autoscuola si era
                fatta portavoce negli anni: professionalità e attenzione verso
                il cliente in primis.
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentAbout;
