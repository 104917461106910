import React from 'react';
import { Link } from 'gatsby';
import '../css/clip.css'

const TeamDetailed = (props) => {

  const { team } = props;

  return (
    <section className="text-gray-400 bg-gradient-to-b from-blue-accent-700 to-blue-accent-400 body-font">
      <div className="container px-8 py-12 lg:py-24 mx-auto">
        <div className="flex lg:flex-row md:flex-col flex-col -m-4">
          {team.map((team) =>
            <div className="py-12 px-12 w-full">
              <div className="items-center h-full flex flex-col sm:justify-start justify-center text-center sm:text-left">
                <img
                  alt="team"
                  className="flex-shrink-0 rounded-lg w-64 h-64 object-cover object-left-top sm:mb-0 mb-4"
                  src={team.img}
                />
                <div className="sm:px-0 sm:py-8 justify-center">
                  <h2 className="py-2 font-roboto font-semibold tracking-tight text-4xl text-center text-white">{team.name}</h2>
                  <h3 className="text-white pb-2 font-roboto font-semibold tracking-tight mb-4 text-2xl text-center">{team.role}</h3>
                  <p className="border-b border-white mx-24 mb-4"></p>
                  <p className="text-white mb-4 text-xl text-center font-roboto font-semibold tracking-normal">
                    {team.description2}
                  </p>
                  <div className="flex items-center justify-center mt-1 space-x-3">
                    <a
                      href={team.facebook}
                      className="text-gray-100 transition-colors duration-100 hover:text-gray-400"
                    >
                      <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                        <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                      </svg>
                    </a>
                    <a
                      href={team.instagram}
                      className="text-gray-100 transition-colors duration-100 hover:text-gray-400"
                    >
                      <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                        <circle cx="15" cy="15" r="4" />
                        <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                      </svg>
                    </a>


                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default TeamDetailed;
