import React from 'react';
import Layout from '../components/layout/Layout';
import MainDescription from '../components/MainDescription';
import { graphql } from 'gatsby'

import TeamDetailed from '../components/TeamDetailed';
import Gallery from '../components/Gallery';
import desc from '../data/chi-siamo/data.json';
import sede from '../data/chi-siamo/sede.json';
import vehicles from '../data/chi-siamo/parco-veicolare.json';

import { GalleryDetailed } from '../components/GalleryDetailed';
import { HeroPromoFeature } from '../components/HeroPromoFeature';
import { TeamHover } from '../components/TeamHover';
import { ContentText } from '../components/ContentText';
import Seo from '../components/layout/SEO';
import ContentAbout from '../components/chi-siamo/ContentAbout';

const team = [
  {
    id: 'team1',
    name: 'Fabio Acri',
    img: '/team/Fabio2.jpg',
    role: "Gestore amministrativo dell'azienda",
    description: "Laureato in scienze politiche all'università di Pisa",
    description2: "Specializzato in lezioni di guida pratica sui mezzi a quattro ruote.",
    facebook: "https://www.facebook.com/fabio.acri",
    instagram: "https://www.instagram.com/faber_1982/"
  },
  {
    id: 'team2',
    name: 'Salvatore Simonelli',
    img: '/team/Salvatore.jpg',
    role: 'Responsabile didattico della scuola',
    description:
      'Diplomato al Liceo Classico Galileo Galilei di Pisa. Possiede le patenti A, B, BE, C, CE, D, DE E Nautica & 12 Miglia',
    description2: "Svolge il ruolo di insegnante/istruttore per tutti i corsi offerti dall'autoscuola",
    facebook: "https://www.facebook.com/DjSalvy",
    instagram: "https://www.instagram.com/salvy_simonelli/"
  },
  {
    id: 'team3',
    name: 'Andrea del Pecchia',
    img: '/team/Andrea2.jpg',
    role: 'Guida pratica e gestione del parco veicolare',
    description:
      'Diplomato a ragioneria, svolge il ruolo di istruttore',
    description2: " Il più giovane del Team. Si occupa delle lezioni di guida pratica e della gestione del parco veicolare.",
    facebook: "https://www.facebook.com/andrea.delpecchia",
    instagram: "https://www.instagram.com/ilpek_95/"
  },
];



const ChiSiamo = () => {


  return (

    <Layout>
      <Seo
        title={"Chi Siamo | Autoscuola Faro a Pisa"}
        description={"Da quasi 50 anni, Autoscuola Faro in centro a Pisa opera con grande professionalità nel settore auto, moto e nautico."}
        keywords={[
          "autoscuola Faro a Pisa",
          "chi è Autoscuola Faro a Pisa"
        ]}
      />
      <ContentAbout/>
      {/* <HeroPromoFeature brand={desc.brand} title={desc.title} par1={desc.par1} par2={desc.par2} /> */}
      {/* <ContentText brand={desc.brand} title={desc.title} par1={desc.par1} par2={desc.par2} /> */}

      <TeamDetailed team={team} />
      {/* <TeamHover/> */}
      <Gallery title={sede.title} gallery={sede.gallery} />
      <GalleryDetailed id="parco-veicolare" title={vehicles.title} vehicles={vehicles.gallery} />
    </Layout>
  )
};

export default ChiSiamo;
